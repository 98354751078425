import { ReactNode } from "react";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja'
import format from "date-fns/format";


class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date: Date | number) {
    return format(date, "yyyy年MM月dd日", {locale: this.locale})
  }

  getCalendarHeaderText(date: Date | number) {
    return format(date, "yyyy年MM月", {locale: this.locale})
  }
}


export default function LocalizedMuiPickerProvider(props: {children: ReactNode}) {
  if(jaLocale && jaLocale.options) {
    jaLocale.options.weekStartsOn = 0;
  }
  return <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale} children={props.children} />
}
