import { useEffect, Fragment } from "react";
import Head from "next/head";
import { AppProps } from "next/app";

import { Provider } from "react-redux";
import store from "@src/redux/store";
import { SnackbarProvider } from "notistack";
import LocalizedMuiPickerProvider from "@src/components/LocalizedMuiPickerProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "@src/theme";
import { loadAuthInfo } from "@src/redux/slices/authSlice";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// register ChartDataLabels to  Chart Object
Chart.register(ChartDataLabels);
if (Chart.defaults.plugins.datalabels)
  Chart.defaults.plugins.datalabels.display = false;

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }

    // Load token from localStorage
    store.dispatch(loadAuthInfo());
  }, []);

  return (
    <Fragment>
      <Head>
        <title>adlog</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <LocalizedMuiPickerProvider>
              <Component {...pageProps} />
            </LocalizedMuiPickerProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </Fragment>
  );
}
